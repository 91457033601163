<template>
	<div v-if="dialogVisible">
		<el-dialog title="发文详情" :visible.sync="dialogVisible" width="700px">
			<div class="addbox" ref="printbox" :style="{maxHeight:setheight?'fit-content':'80vh'}">
				<div class="left_info">
					<div class="mytabble" style="width: 100%;">
						<div class="innerbox">
							<div class="title">
								政协西宁市委员办公室公文处理单
							</div>
							<div class="tablebox" style="width: 100%;">
								<div class="line">
									<div class="namebox" style="width: 50%;justify-content: space-between;">
										<div>
											政宁
										</div>
										<div style="display: flex;align-items: center;">
											{{info.zh}} 号
										</div>
									</div>
									<div class="namebox" style="width: 50%;justify-content: space-between;">
										<div style="width: 50%;text-align: left;display: flex;align-items: center;">
											缓急
											<span style="width: fit-content;margin-left: 10px;">{{info.jjcd}}</span>
										</div>
										<div style="width: 50%;text-align: left;display: flex;align-items: center;">
											密级
											<span style="width: fit-content;margin-left: 10px;">{{info.mj}}</span>
										</div>
									</div>
								</div>
								<div class="line hei300" style="height: 300px;display: flex;">
									<div class="namebox hei300" style="width: 50%;justify-content: space-between;align-items: baseline;height: 100%;">
										<div style="display: flex;align-items: flex-start;width: 100%;justify-content: space-between;height: 500px;">
											<div>
												签发
											</div>
											<div class="hei250" style="width: 80%;margin-top: 20px;height: 250px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
												<div style="width: 100%;margin-right: 5%;text-align: left;">{{info.qfyj}}</div>
												<div style="width: 100%;display: flex;flex-direction: column;align-items: flex-end;justify-content: flex-end;">
													<img :src="info.qf_url" alt="" style="width: 40%;height: auto;"/>
													<div style="font-size: 12px;">{{info.qftime}}</div>
												</div>
												
											</div>
										</div>										
									</div>
									<div class="namebox hei300" style="width: 50%;justify-content: space-between;align-items: baseline;height: 100%;padding: 0;">
										<div style="width: 100%;height: 100%;">
											<div class="fhbox" style="height: 34%;width: 100%;border-bottom: none;">
												<div>
													复核
												</div>
												<div class="qmbox" v-if="info.fhlog&&info.fhlog[info.fhlog.length-1]">
													<img :src="info.fhlog[info.fhlog.length-1].qmurl" alt=""  style="width: auto;max-height: 45px;"/>
													<div style="font-size: 12px;height: 100%;display: flex;align-items: flex-end;justify-content: flex-end;">
														{{info.fhlog[info.fhlog.length-1].addtime}}
													</div>
												</div>
											</div>
											<div class="fhbox" style="height: 33%;width: 100%;border-bottom: none;">
												<div class="qmbox" v-if="info.fhlog&&info.fhlog[info.fhlog.length-2]">
													<img :src="info.fhlog[info.fhlog.length-2].qmurl" alt=""  style="width: auto;max-height: 45px;"/>
													<div style="font-size: 12px;height: 100%;display: flex;align-items: flex-end;justify-content: flex-end;">
														{{info.fhlog[info.fhlog.length-2].addtime}}
													</div>
												</div>
											</div>
											<div class="fhbox" style="height: 33%;width: 100%;">
												<div class="qmbox" v-if="info.fhlog&&info.fhlog[info.fhlog.length-3]">
													<img :src="info.fhlog[info.fhlog.length-3].qmurl" alt=""  style="width: auto;max-height: 45px;"/>
													<div style="font-size: 12px;height: 100%;display: flex;align-items: flex-end;justify-content: flex-end;">
														{{info.fhlog[info.fhlog.length-3].addtime}}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="line" style="min-height: 40px;height: auto;display: table;">
									<div class="namebox" style="width: 50%;justify-content: space-between;align-items: baseline;display: table-cell;">
										<div style="display: flex;align-items: flex-start;width: 100%;justify-content: space-between;">
											<div>
												初核
											</div>
											<div style="width: 80%;text-align: left;">
												<img :src="info.sh_url" alt="" style="width: 35%;height: auto;"/>
												<div style="font-size: 12px;height: 100%;display: flex;align-items: flex-end;justify-content: flex-end;">
													{{info.sh_time}}
												</div>
											</div>
										</div>										
									</div>
									<div class="namebox" style="width: 50%;justify-content: space-between;align-items: baseline;height: 100%;display: table-cell;">
										<div style="display: flex;align-items: flex-start;width: 100%;justify-content: space-between;">
											<div>
												拟稿
											</div>
											<div style="width: 80%;text-align: left;">
												<img :src="info.ng_url" alt="" style="width: 35%;height: auto;"/>
												<div style="font-size: 12px;height: 100%;display: flex;align-items: flex-end;justify-content: flex-end;">
													{{info.addtime}}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="line" style="height: 60px;">
									<div class="namebox" style="width: 100%;justify-content: flex-start;align-items: flex-start;">
										<div>
											标题
										</div>
										<span style="width: fit-content;margin-left: 20px;">{{info.name}}</span>
									</div>
								</div>
								<div class="line" style="height: 75px;">
									<div class="namebox" style="width: 100%;justify-content: flex-start;align-items: flex-start;">
										<div>
											主送
										</div>
										<div style="width: 90%;padding: 0 15px;display: flex;align-items: center;">
											{{info.zsuser_name}}
										</div>
									</div>
								</div>
								<div class="line">
									<div class="namebox" style="width: 100%;justify-content: flex-start;align-items: flex-start;">
										<div>
											抄报
										</div>
										<span style="width: fit-content;margin-left: 20px;">{{info.cb}}</span>
									</div>
								</div>
								<div class="line" style="height: auto;">
									<div class="namebox" style="width: 100%;justify-content: flex-start;align-items: center;">
										<div>
											抄送
										</div>
										<div style="width: 90%;padding: 0 15px;display: flex;align-items: center;">
											{{info.csr.length>=1?info.csr.map((item)=>{return item.name}).join(','):''}}
										</div>
									</div>
								</div>
								<div class="line">
									<div class="namebox" style="width: 100%;justify-content: flex-start;align-items: flex-start;">
										<div>
											附件
										</div>
										<div style="width: 90%;text-align: left;padding: 0 15px;cursor: pointer;" @click="open(info.fj[0].fullurl)">
											{{info.fj[0].filename}}		
										</div>
									</div>
								</div>
								<div class="line">
									<div class="namebox" style="width: 100%;justify-content: flex-start;align-items: flex-start;">
										<div>
											主题词
										</div>
										<span style="width: fit-content;margin-left: 20px;">{{info.ztc}}</span>
									</div>
								</div>
								<div class="line">
									<div class="namebox" style="width: 50%;justify-content: space-between;">
										<div>
											发至
										</div>
										<div>
											
										</div>
									</div>
									<div class="namebox" style="width: 50%;justify-content: space-between;">
										<div style="width: 50%;text-align: left;">
											份数
										</div>
										<div style="width: 50%;text-align: right;">
											{{info.fen}}
										</div>
									</div>
								</div>
								<div class="line" style="height: 40px;">
									<div class="namebox" style="width: 50%;justify-content: space-between;">
										<div>
											交印
										</div>
										<div style="cursor: pointer;position: relative;">
											{{info.jy}}
										</div>
									</div>
									<div class="namebox" style="width: 50%;justify-content: space-between;">
										<div style="width: 50%;text-align: left;">
											印出
										</div>
										<div style="width: 50%;text-align: right;cursor: pointer;position: relative;">
											{{info.yc}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>	
					<!-- <div class="title">
						正文
					</div>
					<div v-html="info.zhengwen" style="padding: 15px;line-height: 25px;">
						
					</div> -->
					<div style="width: 100%;display: flex;margin-bottom: 10px;margin-top: 20px;">
						<div>文单附件列表：</div>
						<div style="width: 80%;">
							<div v-for="(item2,index2) in info.fj" :key="index2" style="color: #409EFF;margin-bottom: 5px;cursor: pointer;width: 80%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" @click="open(item2.fullurl)">
								{{item2.filename}}
							</div>
						</div>
					</div>
					<!-- <div style="width: 100%;display: flex;margin-bottom: 10px;">
						<div>办理结果附件：</div>
						<div style="width: 80%;">
							<div v-for="(item2,index2) in info.blfj" :key="index2" style="color: #409EFF;margin-bottom: 5px;cursor: pointer;width: 80%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" @click="open(item2.fullurl)">
								{{item2.filename}}
							</div>
						</div>
					</div> -->
					<div style="width: 100%;margin-bottom: 10px;">
						抄送：{{info.csr.length>=1?info.csr.map((item)=>{return item.name}).join(','):'无'}}
					</div>
				</div>
			</div>
			<div class="btnbox">
				<el-button type="" size="mini" class="font14" @click="dialogVisible=false">关闭</el-button>
				<el-button v-show="showprint" type="success" size="mini" @click="print">打 印</el-button>
			</div>
		</el-dialog>

	</div>

</template>

<script>
	export default {
		props:{
			showprint:{
				type:Boolean,
				defaut:false
			}
		},
		data() {
			return {
				dialogVisible: false,
				info:'',
				setheight:false
			}
		},
		methods: {
			open(url){
				window.open(url,'_blank')
			},
			print(){
				this.setheight = true
				this.$nextTick(()=>{
					this.$printPage(this.$refs.printbox)
				})
				
				setTimeout(()=>{
					this.setheight = false
				},2000)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.tlename {
			width: 120px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;
		max-height: 80vh;
		
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
		border-top: 1px solid #eee;
	}

	

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	.innerbox{
		width: 100%;
		background-color: #fff;
		padding: 20px 10px;
	}
	.title{
		font-size: 22px;
		color: #333;
		width: 100%;
		text-align: center;
	}
	.line{
		display: flex;
		align-items: center;
		height: 50px;
		width: 100%;
	}
	.namebox{
		height: 100%;
		width: 12%;
		border: 1px solid #333;
		padding: 7px;
		text-align: center;
		border-right: none;
		border-bottom: none;
		display: flex;
		align-items: center;
		justify-content: center;
		span{
			width: 100%;
		}
	}
	.namebox:nth-last-child(1){
		border-right: 1px solid #333;
	}
	.value{
		height: 100%;
		width: 35%;
		padding:7px;
		text-align: center;
		border: 1px solid #333;
		word-wrap: break-word;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: none;
		span{
			width: 100%;
			
		}
	}
	.value:nth-child(2){
		border-right: none;
	}
	.line:nth-last-child(1){
		border-bottom: 1px solid #333;
	}
	.btm_btn{
		position: fixed;
		bottom: 0;
		width: 100vw;
		left: 0;
		padding: 10px 20px;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.tablebox{
		margin-top: 15px;
	}
	.line{
		display: flex;
		align-items: center;
		height: 50px;
		width: 100%;
		color: #E23D00;
	}
	.namebox{
		height: 100%;
		width: 12%;
		border: 1px solid #E23D00;
		padding: 7px;
		text-align: center;
		border-right: none;
		border-bottom: none;
		display: flex;
		align-items: center;
		justify-content: center;
		text{
			width: 100%;
		}
	}
	.namebox:nth-last-child(1){
		border-right: 1px solid #E23D00;
	}
	.value{
		height: 100%;
		width: 35%;
		padding:7px;
		text-align: center;
		border: 1px solid #E23D00;
		word-wrap: break-word;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: none;
		text{
			width: 100%;
			
		}
	}
	.value:nth-child(2){
		border-right: none;
	}
	.line:nth-last-child(1){
		border-bottom: 1px solid #E23D00;
	}
	.btm_btn{
		position: fixed;
		bottom: 0;
		width: 100vw;
		left: 0;
		padding: 10px 20px;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.tablebox{
		margin-top: 15px;
	}
	.fhbox{
		border-bottom: 1px solid #E23D00;		
		padding: 10px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.fhbox:nth-last-child(1){
		border-bottom: none;
	}
	.qmbox{
		margin-top: 10px;
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
	}
	
	@media print{
		.hei300{
			height: 300px !important;
		}
		.hei250{
			height: 250px !important;
		}
	}
</style>